/**
 * 生成ga的 name/ici
 * @param {Object} propData 组件ccc数据
 * @param {String} sceneName 场景名称
 * @param {String|Number} tagId 人群Id
 * @param {String} abtInfo abt信息， poskey_分支类型，多个实验使用逗号分割
 * @param {String} placeholder gaName的差异化 TY/CS, 不传会有一个{-}的占位
 */
const buildCccGaParams = ({ propData, sceneName = 0, tagId, abtInfo = 0, placeholder = null }) => {
  if (!propData) return console.error('lose param: propData (ccc config data)')
  // http://wiki.dotfashion.cn/pages/viewpage.action?pageId=205784109
  // CCCSN={场景名称}_TN={tab名称}（m和pwa首页有多语言，写死的上报HomePage-tab id）_ON={运营位名称}_OI={运营位id}_CN={组件名称}_TI={人群id }_aod={精投标识}_TY={跳转id/跳转类型/跳转链接}_ABT={poskey_分支类型}
  const { oper_name = 0, oper_id = 0, content = null } = propData
  const iciParams = [
    `CCCSN=${sceneName}`,
    `ON=${oper_name}`,
    `OI=${oper_id}`,
    `CN=${content && content.name || 0}`,
    `TI=${tagId}`,
    `aod=${content && content.props.style.aod_id || 0}`
  ]

  const gaName = iciParams.concat([
    placeholder ? placeholder : '{-}',
    `ABT=${abtInfo || 0}`
  ])

  return {
    name: gaName.join('_'),
    ici: iciParams.join('_')
  }
}
/**
 *
 * @param {Object} contextContent ccc内容配置
 * @param {Object} pageInfo SaPageInfo 自有的页面信息
 * @param {String|Number} tabSceneId tabID
 * @param {String|Number} comStyleIndex 组件样式id
 * @param {String|Number} tagId 人群Id
 * @param {String|Number} index 运营位位置Id
  */
const buildCccSaParams = ({ contextContent = {}, propData, pageInfo = {}, index = 1, tabSceneId = 0, comStyleIndex = 0, tagId }) => {
  // http://wiki.dotfashion.cn/pages/viewpage.action?pageId=42310569
  // SPM生成逻辑，由：页面ID`tabID`场景ID_场景类型`模板ID`区块ID`运营位ID`运营位位置ID`样式ID`人群ID`坑位ID`热区ID`层级ID`跳转类型ID `组件ID
  // 这里只拼接到人群Id
  const { content, oper_id } = propData
  const saName = [
    // 页面id
    pageInfo.page_id || 0,
    // tabId
    tabSceneId,
    // tabId_场景类型
    `${contextContent.scene_id || contextContent.sceneId || 0}_${contextContent.module || contextContent.scene_module || 0}`,
    // 模板ID
    contextContent.template_id || contextContent.templateId || 0,
    // 区块id
    content?.blockName || 0,
    // 运营位Id
    oper_id,
    // 运营位位置Id
    index,
    // 样式Id
    comStyleIndex,
    // 人群Id
    tagId
  ].join('`')

  return saName
}
/**
 * abt信息拼接，poskey_分支类型
 * @param {Array} abtList 多个abt信息的集合
 * @param {String} separator 多个abt信息的拼接符号，默认逗号
 */
const buildCccAbtInfo = ({ abtList = [], separator = ',', onlyPoskey = false } = {}) => {
  // 兼容不同数据结构
  return abtList.reduce((acc, cur) => {
    if (!cur) return acc
    const posKey = cur['posKey'] || cur['poskey'] || ''
    if (onlyPoskey && posKey) {
      acc.push(posKey)
      return acc
    }
    const type = cur['type'] || 'default'
    posKey && acc.child && acc.child.length && acc.child.forEach(_ => {
      acc.push(`${posKey}_${type}_${_.bid}`)
    })
    return acc
  }, []).join(separator)
}


const getTabPageId = () => {
  if (typeof window === 'undefined') return ''
  return window.getSaPageInfo?.tab_page_id || (window.SaPageInfo ? (SaPageInfo.page_name + SaPageInfo.start_time) : '')
}

/**
 * 获取SA的资源位信息贫瘠
 * @param {*} sceneName 场景名称
 * @param {*} operationName 运营名称
 * @param {*} operationComponentName 运营组件名称
 * @param {*} comIndex 组件位置
 * @param {*} positionIndex 当前资源在组件中的位置
 * @param {*} hotZone 热区信息
 * @param {*} item 当前资源的信息
 * @returns
 */
const getSaCccSourceFrom = ({ sceneName, operationName, operationComponentName, comIndex = 1, positionIndex = 1, item = {} }) => {
  const jc = getSaJumpTypeNew(item)
  const srcIdentifier = [`on=${operationName}`, `cn=${operationComponentName}`, 'hz=0', `ps=${comIndex}_${positionIndex}`, `jc=${decodeURIComponent(jc)}`].join('`')

  const tabPageId = getTabPageId()
  const urlParams = [`src_module=${sceneName}`, `src_identifier=${encodeURIComponent(srcIdentifier)}`, `src_tab_page_id=${tabPageId}`].join('&')

  return {
    src_module: sceneName,
    src_identifier: srcIdentifier,
    src_tab_page_id: tabPageId,
    url_params: urlParams,
  }
}

/**
 * 获取跳转的类型
 * @param {*} hrefTarget
 * @param {*} hrefType
 * @param {*} hrefType2
 * @param {*} hrefTag
 * @returns
 */
const getJumpType = (hrefTarget, hrefType, hrefType2, hrefTag) => {
  if (hrefTag) {
    return `${hrefTag || 0}_${hrefTarget || 0}`
  }
  // 真实、选品返回对应分类ID
  if (['real', 'realOne', 'itemPicking'].indexOf(hrefType) > -1) {
    return hrefTarget
  }

  // 特殊分类取具体的分类
  if (hrefType === 'special') {
    if (!hrefType2) {
      return hrefType
    }
    if (['discountList', 'shein_picks'].indexOf(hrefType2) > -1) {
      return `SheinPicks_${hrefTarget || '0'}`
    }
    return hrefType2
  }

  // 专题页取专题标识符
  if (hrefType === 'activity') {
    return hrefTarget
  }

  // 问卷取 hrefType + hrefTarget
  if (hrefType === 'survey') {
    return `${hrefType}${hrefTarget}`
  }

  // web链接取 hrefTarget 保存的是链接
  if (hrefType === 'webLink') {
    return hrefTarget
  }

  if (hrefType === 'noJump') {
    return 'noJump'
  }

  // 文章页取文章ID
  if (hrefType === 'article') {
    return hrefTarget
  }

  // love romwe
  if (hrefType === 'loveromwe') {
    return `${hrefType}_${hrefTarget || 0}`
  }

  if (['discountList', 'shein_picks'].indexOf(hrefType) > -1) {
    return `SheinPicks_${hrefTarget || '0'}`
  }

  return hrefType || '0'
}

// wiki.pageId=846334114
const getSaJumpTypeNew = (item) => {
  const typeMapToTarget = {
    real: 'real',
    realOne: 'real',
    realTwo: 'real',
    itemPicking: 'itemPicking',
    article: 'article',
    webLink: 'url',
    survey: 'survey',
    activity: 'activity',
    game: 'game',
    singleReplay: 'singleReplay',
    singlePreview: 'singlePreview',
    singleVideo: 'singleVideo',
    lookBook: 'lookBook',
    sku: 'goodsid',
    category: 'category',
    prime: 'prime',
    activityNew: 'activityNew',
    sheinxDesignerInformation: 'sheinxDesignerInformation',
    sheinxDesignerList: 'sheinxDesignerList'
  }

  const typeMapDefault = {
    noJump: 'noJump_0',
    trial: 'freetrial_0',
    giftCard: 'giftcard_0',
    dailyNew: 'dailynew_0',
    whatsnew: 'dailynew_0',
    whatsNew: 'dailynew_0',
    flashSale: 'flashsale_0',
    lightningDeal: 'flashsale_0',
    discountList: 'sheinpicks_' + (item.hrefTarget || '0'),
    promoDiscount: 'sheinpicks_' + (item.hrefTarget || '0'),
    gals: 'gals_0',
    video: 'media_0',
    signPage: 'checkin_0',
    comingSoon: 'comingsoon_0',
    videoList: 'videoList_0',
    liveEntrace: 'liveEntrace_0',
    liveList: 'liveList_0',
    couponCenter: 'couponcenter_0',
    userInfoEdit: 'selfinformation_0',
    sheinxDesignerInformation: 'sheinxDesignerInformation' + (item.hrefTarget || '0'),
    sheinxDesignerList: 'sheinxDesignerList'
  }

  let saResContent = '0'
  if (['real', 'realOne', 'realTwo', 'itemPicking'].indexOf(item.hrefType) > -1 || typeMapToTarget[item.hrefType]) {
    if (['real', 'realOne', 'realTwo', 'itemPicking'].indexOf(item.hrefType) > -1) {
      let hrefType = item.hrefType.split('_')[0]
      saResContent = `${typeMapToTarget[hrefType]}_${item.hrefTarget || 0}`
    } else if (item.hrefType === 'activity') {
      saResContent = `${typeMapToTarget[item.hrefType]}_${item.hrefTarget.toString().trim() || 'noJump'}`
    } else {
      saResContent = `${typeMapToTarget[item.hrefType]}_${item.hrefTarget || 0}`
    }
  } else if (typeMapDefault[item.hrefType]) {
    saResContent = typeMapDefault[item.hrefType]
  } else if (item.hrefType === 'special') {
    // special 类型需要重新拿 hrefType2 来判断
    saResContent = typeMapDefault[item.hrefType2] || item.hrefType + '_0'
  } else if (item.hrefType) {
    saResContent = item.hrefType + '_0'
  }

  return saResContent
}


/**
 * 判断跳转目标是否是单页面中的链接
 * @param {*} item
 */
const isInSpa = (item = {}) => {
  return !['webLink', 'survey', 'article', 'giftCard', 'game'].includes(item.hrefType)
}

/**
 * 自有sa埋点跳转类型映射
 */
const CCC_SA_JUMP_TYPE = {
  real: 2,
  realOne: 2,
  realTwo: 3,
  itemPicking: 4,
  special: 5,
  activity: 6,
  sku: 7,
  webLink: 10,
  noJump: 11,
  flashBuy: 12,
  signPage: 13,
  survey: 27,
  trial: 29,
  dailyNew: 16,
  discountList: 15,
  SheinPicks: 15,
  flashSale: 12,
  giftCard: 24,
  gals: 8,
  video: 9,
  category: 26,
  couponCenter: 25,
  userInfoEdit: 28,
  lookBook: 32,
  article: 31,
  game: 33,
}

const CCC_STYLE_ID = {
  ITEM_IMAGE1: '1', // 图片
  ITEM_IMAGE2: '2',
  ITEM_IMAGE3: '3',
  ITEM_IMAGE4: '4',
  ITEM_IMAGE4_NORMAL: '5',
  ITEM_IMAGE5: '5',
  THREE_ITEMS: '1', // 商品
  FOUR_ITEMS: '2',
  FIVE_ITEMS: '3',
  ITEM_IMAGE_CAROUSEL: '1', // 轮播图片
  FLASH_SALE: '1', // 闪购
  LEFT_CAROUSEL: '1', // 组合
  RIGHT_CAROUSEL: '2',
  DEFAULT_VIDEO: '1', // 视频
  BANNER_WITH_TIME: '1', // topbanner
  BANNER_WITHOUT_TIME: '2',
  HORIZONTAL_ITEMS: '1',
  VERTICAL_ITEMS: '2',
  TAIL_ITEMS_LIST_COMPONENT: '2',
  ITEM_IMAGE_MIX_LEFT: '7', // 1:2 组合图
  ITEM_IMAGE_MIX_RIGHT: '6', // 2:1 组合图
  ITEM_IMAGE_TEXT_CAROUSEL: '2', // 卡片轮播
  ITEM_PICTURE_WORD_ONE_ROW: '1', // 一文多图单列
  ITEM_PICTURE_WORD_DOUBLE_ROWS: '2', // 一文多图双列
  COUNT_DOWN1: '1',
  COUNT_DOWN2: '2',
  ITEM_IMAGE_FOUR_COLS_CAROUSEL: '3', // 一行四列轮播
  ITEM_IMAGE_DOUBLE_COLS_CAROUSEL: '4' // 一行两列轮播
}

export {
  isInSpa,
  CCC_STYLE_ID,
  CCC_SA_JUMP_TYPE,
  buildCccGaParams,
  buildCccAbtInfo,
  buildCccSaParams,
  getSaCccSourceFrom,
  getSaJumpTypeNew,
  getJumpType,
}
