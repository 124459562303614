import { getCurrentToRoute } from './utils'
import { ORIGIN_QUERY_FILTER_KEYS, SEARCH_SOURCE_ENUM } from 'public/src/pages/common/search_words/searchConst.js'
import { ROUTE_REGEX } from './constant.js'

const isSearch = (type) => type === 'search' // 搜索(全站，店铺，专题)
const isAllSearch = (type, query) => isSearch(type) && !isStoreSearch(type, query) && !isBrandSearch(type, query) // 全站搜索
const isStoreSearch = (type, query) => isSearch(type) && query.search_type === 'store' // 店铺搜索
const isBrandSearch = (type, query) => isSearch(type) && query.search_type === 'brand' // 专题搜索

function isGeneralProductList(route) {
  return route.name === 'product-list-v2'
}

// 转query的词 (从 图文，锦囊，分布式筛选 来)
function getQueryWords(route) {
  return route?.query?.filterAttrIds?.split('`')?.map(str => str.split(',')[0]) || []
}

/**
 * 通过存储在 store 中的状态来获取列表请求主参数（搜索，id等）
 * @param store
 * @param route
 */
function getListMainParamsByStore(store, route) {
  const { catInfo, searchKeywords } = store || {}
  const type = catInfo?.type
  // const path = route.path || location.pathname

  switch (type) {
    case 'search':
      return {
        type: 'search',
        mainParams: {
          keywords: searchKeywords?.is_suggest_res ? searchKeywords?.suggest_words : searchKeywords?.origin_words,
        }
      }
    // case 'picks': {
    //   return {
    //     hrefTarget: getHrefTarget({ path }), // 匹配 ccc navList
    //     navId,
    //   }
    // }
  }
}

/**
 * 通过路由的 path 来获取列表请求主参数（搜索，id等）
 * @param route
 */
function getListMainParamsByRoute (route) {
  const path = route.path || location.pathname

  let match = path.match(ROUTE_REGEX.search)
  if (match) {
    return {
      type: 'search',
      mainParams: {
        keywords: [
          decodeURIComponent(match[1]).slice(0, 150),
          ...getQueryWords(route),
        ].join(' ')
      }
    }
  }

  return {}
}

// 获取接口的主参数，泛列表和搜索有差异的
function getListMainParams(requestInfo, store, route) {
  const { type, navId } = requestInfo || {}
  if (isGeneralProductList(route) || isPicksRoute(route)) {
    if (type !== 'firstload') {
      return getListMainParamsByStore(store, route)
    } else {
      return getListMainParamsByRoute(route)
    }
  }
}

function fixSearchSource(query) {
  const { pic_nav_id = '', search_source = '' } = query
  let fix = ''
  // 图文导航
  if (pic_nav_id) {
    fix = SEARCH_SOURCE_ENUM.IMG_NAV
  }
  // 有图文导航走图文导航，没有图文导航在判断筛选
  const hasFilter = ORIGIN_QUERY_FILTER_KEYS.some(key => query[key])
  // 需求TR-19517
  // 将线上点击图文导航后的search_source由1更改为6，不区分是否为点击后走筛选逻辑、或者点击后走query入参，统一改为6
  // && search_source !== '6'处理，当前图文导航点击后变成query（图文模块导行消失）, 这个时候再点击筛选应该继续为6，不应该变为1
  if (!pic_nav_id && hasFilter && search_source !== '6') {
    fix = SEARCH_SOURCE_ENUM.USER_INPUT
  }
  return fix || search_source
}

// 获取列表类型
function getServiceType(query) {
  const { sil_type } = query || {}
  
  if (sil_type === 'entity') {
    return 1
  } else if (sil_type === 'selection') {
    return 6
  }
}

// vuex 中的数据
const getVuexInfo = (toRoute) => {
  const state = _gb_app_?.$store?.state || {}
  const getters = _gb_app_?.$store?.getters
  let getterKey = ''

  if (isGeneralProductList(toRoute)) {
    getterKey = 'productList_v2'
  } 
  // else if (isPicksRoute(toRoute)) {
  //   getterKey = 'shein-picks'
  // } else if (isRecProductListRoute(toRoute)) {
  //   getterKey = 'rec_product_list'
  // } else if (isRouteNameMatch(toRoute, 'discount-channel-list')) {
  //   getterKey = 'discount_channel_list'
  // } else {
  //   getterKey = ''
  // }

  return {
    goods: getters?.[`${getterKey}/goods`] || [],
    catInfo: getters?.[`${getterKey}/catInfo`] || {},
    searchKeywords: getters?.[`${getterKey}/searchKeywords`] || {},
    // language: getters?.[`${getterKey}/language`] || {},
    atomicParams: getters?.[`${getterKey}/atomicParams`] || {},
    stateResults: state[getterKey]?.Results || {},
  }
}

// 已浏览过的商品
const getViewedGoods = (toRoute, requestInfo, goods) => {
  // 只有加载更多才带上 viewedGoods
  if ((isGeneralProductList(toRoute) || isPicksRoute(toRoute)) && requestInfo.type === 'nextpage' && requestInfo.query.page <= 12) {
    const viewedGoods = goods.slice(0, 240).map(goodItem => {
      const goodsId = goodItem.goods_id
      const spuId = goodItem.productRelationID
      return goodsId + '-' + spuId
    }).join(',')

    return viewedGoods
  }

  return ''
}

// filterAttrIds的拼接逻辑在这: public/src/pages/product_list_v2/js/search.common.js -> 方法handleSearchFilterQuery
// list[3] - queryType: picTopNav 图文导航, searchFilter 分布式筛选, flowCellSearch 锦囊
const getClickedQuries = (filterAttrIds = '') => {
  if (!filterAttrIds) return ''
  return filterAttrIds.split('`')
    ?.map(str => { 
      const list = str.split(',')
      return list[3] === 'picTopNav' ? list[1] : `${list[1]}_${list[2]}`
    })
    ?.join('-') ?? ''
}

// 一些公共参数
const getCommonParams = (query, searchKeywords) => {
  const isQuickShip = query.tag_ids === 'quickship' // 标签云筛选了quickship
  const isMallTag = query.mall_tag || ''
  const tagIds = (isQuickShip || isMallTag) ? '' : query.tag_ids // 标签云筛选
  const isInListSearch = !(query.sil_without_entry || !query.sil_type) // 是否是列表页内搜索
  return {
    // query相关
    quickship: isQuickShip ? '1' : '', // quickship=1表示过滤快速发货，quickship=0表示过滤非快速发货，quickship不传表示不过滤
    filter: query.attr_ids || '', // 属性筛选
    catId: query.child_cat_id || '', // 分类筛选
    choosedNavId: query.pic_nav_id || '', // 选中的图文导航id
    choosedNavType: query.pic_nav_type || '', // 选中的图文导航类型
    filterTagIds: query.tsp_ids || '', // tsp标签筛选，“1_60001803,1_60001803,2_60001803”（需要带标签组）
    maxPrice: query.max_price ?? '', // 最大价格
    minPrice: query.min_price ?? '', // 最小价格
    tagIds, // 标签云筛选
    channelId: query.channel_id || '', // 人群id
    mallCode: isMallTag ? query.tag_ids : '',
    serviceType: isInListSearch ? getServiceType(query) : '', // 列表内搜索，列表的类型
    forceNotCorrect: query.dont_use_suggest_word || '', // 是否需要纠错： 1: 搜索强制不走纠错逻辑（即用原词召回） 0：搜索走正常的纠错逻辑
    listCatId: isInListSearch ? query.list_cat_id : '', // 商品列表分类ID
    clickedQueries: getClickedQuries(query.filterAttrIds), // 筛选转query的场景新增参数,传点击过的属性id（attr_id_attr_values-attr_id_attr_values 和filter入参格式一致）
    storeCode: query.store_code || '', // 店铺code， 自营店铺为1， 默认为空
    intentChannelId: query.intent_channel_id || '', // 频道筛选ID

    // 搜索词相关
    forceSuggest: searchKeywords?.double_lang_correct ? '1' : '0', // 双语纠错标识字段, 1: 是  0: 否
  }
}

// 主搜POSKEY
const getSearchPoskey = (query) => {
  const isInListSearch = !(query.sil_without_entry || !query.sil_type) // 是否是列表页内搜索
  return !isInListSearch ? 'SearchPageSort' : 'ListSearchSort'
}

// 商品接口入参
const getGoodsParams = (requestInfo, newSrcTabPageId) => {
  const toRoute = getCurrentToRoute() || {}
  const query = requestInfo.query || {}
  const { goods, catInfo, searchKeywords, atomicParams, stateResults } = getVuexInfo(toRoute)
  const { type, mainParams } = getListMainParams(requestInfo, { catInfo, searchKeywords }, toRoute)
  const { 
    quickship,
    filter,
    catId,
    choosedNavId,
    choosedNavType,
    filterTagIds,
    maxPrice,
    minPrice,
    tagIds,
    channelId,
    mallCode,
    serviceType,
    forceNotCorrect,
    listCatId,
    clickedQueries,
    forceSuggest,
    storeCode,
    intentChannelId,
  } = getCommonParams(query, searchKeywords)

  /**
   * TODO: 暂时不走bff
   * deliverItemLimitObject: getDeliverItemLimitObject({ stateResults }),
   * cate_ids: getCateIds({ catInfo, stateResults }),
   */

  /**
   * 会员态怎么办？TODO:
   * reqSheinClub: atomicParams.reqSheinClub,
   * isPaid: atomicParams.isPaid,
   */

  const params = {
    ...mainParams,
    quickship,
    filter,
    cat_id: catId,
    choosed_nav_id: choosedNavId,
    choosed_nav_type: choosedNavType,
    filter_tag_ids: filterTagIds,
    max_price: maxPrice,
    min_price: minPrice,
    tag_ids: tagIds,
    channelId,
    mall_code: mallCode,
    force_not_correct: forceNotCorrect,
    adp: query.adp || '',
    page: query.page || 1,
    limit: query.limit || 10,
    filter_goods_infos: getViewedGoods(toRoute, requestInfo, goods),
    is_force: query.is_force || '',
    sort: query.sort || '',
    source: query.source || '',
    source_status: query.source_status || '',
  }

  // 搜索(全站，店铺，专题)
  if (isSearch(type)) {
    Object.assign(params, {
      service_type: serviceType,
      list_cat_id: listCatId,
      clicked_queries: clickedQueries,
      force_suggest: forceSuggest,
      src_tab_page_id: newSrcTabPageId || window?.getSaPageInfo?.tab_page_id || '-',
      poskey: getSearchPoskey(query),
    })
  }

  if (isAllSearch(type, query)) { // 全站搜索
    Object.assign(params, {
      scene: 'all',
      search_source: fixSearchSource(query),
    })
  } else if (isStoreSearch(type, query)) { // 店铺搜索
    Object.assign(params, {
      scene: 'store',
      store_code: storeCode,
      ignore_direct: 'true',
    })
  } else if (isBrandSearch(type, query)) { // 专题搜索
    Object.assign(params, {
      intent_channel_id: intentChannelId,
      ignore_direct: 'true',
      search_source: fixSearchSource(query),
    })
  }

  // 抹掉空参
  Object.keys(params).forEach(key => {
    if ((key === 'min_price' || key === 'max_price' || key === 'source_status') && params[key] === 0) return
    !params[key] && delete params[key]
  })

  return params
}

// 筛选接口入参（图文导航，属性，分类，tsp筛选，标签云）
const getFilterParams = (requestInfo) => {
  const toRoute = getCurrentToRoute() || {}
  const query = requestInfo.query || {}
  const { catInfo, searchKeywords } = getVuexInfo(toRoute)
  const { type, mainParams } = getListMainParams(requestInfo, { catInfo, searchKeywords }, toRoute)
  const { 
    quickship,
    filter,
    catId,
    choosedNavId,
    choosedNavType,
    filterTagIds,
    maxPrice,
    minPrice,
    tagIds,
    channelId,
    mallCode,
    serviceType,
    forceNotCorrect,
    listCatId,
    clickedQueries,
    forceSuggest,
    intentChannelId,
  } = getCommonParams(query, searchKeywords)

  const params = {
    ...mainParams,
    quickship,
    filter,
    catId,
    choosedNavId,
    choosedNavType,
    filterTagIds,
    maxPrice,
    minPrice,
    tagIds,
    channelId,
    chooseTag: tagIds,
    mallCode,
    forceNotCorrect,
    cancelFilterTagIds: filterTagIds,
    needFilter: '1', // 是否需要filter数据：0 不需要 1 需要(默认) 
    needNavIndex: '1', // 是否需要图文导航数据：0 不需要 1 需要(默认)
    needTagCloud: '1', // 是否需要标签云数据：0 不需要 1 需要(默认)
    positionAbt: '', // 筛选信息流ABT
    maxDisc: '', // 最大折扣
    mixDisc: '', // 最小折扣
    enterMallCode: query.mallCode || '', // 入口默认的mallcode，用来屏蔽mall标签云
    cancelFilter: '', // 用户取消的筛选项
    chooseIds: query.choose_ids, // 已选中的分类id和其所有父类id用逗号拼接
    showAllCategory: query.showAllCategory || '', // 是否展示全部分类 // 单层级类目，需要返回全部类目
  }

  // 搜索(全站，店铺，专题)
  if (isSearch(type)) {
    Object.assign(params, {
      serviceType,
      listCatId,
      clickedQueries,
      forceSuggest,
      poskey: getSearchPoskey(query)
    })
  }

  if (isAllSearch(type, query)) { // 全站搜索
    Object.assign(params, {
      scene: 'all', // 搜索场景,all:全站搜索;store:店铺内搜索，store_code必传，trendChannel:趋势频道内搜索
    })
  } else if (isStoreSearch(type, query)) { // 店铺搜索
    Object.assign(params, {
      scene: 'store', // 搜索场景,all:全站搜索;store:店铺内搜索，store_code必传，trendChannel:趋势频道内搜索
      storeCode: query.store_code
    })
  } else if (isBrandSearch(type, query)) { // 专题搜索
    Object.assign(params, {
      intentChannelId,
    })
  }

  // 抹掉空参
  Object.keys(params).forEach(key => {
    if ((key === 'minPrice' || key === 'maxPrice') && params[key] === 0) return
    !params[key] && delete params[key]
  })

  return params
}

/**
 * 
 * @param {Object} requestInfo 
 * @param {String} requestInfo.type firstload 首次进入列表, nextpage 翻页, refresh 筛选
 * @param {Object} requestInfo.query 
 * @param {String} requestInfo.query.page 页码
 * @param {String} requestInfo.query.limit 商品数
 * @param {String} requestInfo.query.search_type 搜索子类型 store 门店搜索, brand 专题搜索
 * @param {String} requestInfo.query.xxx 剩下一些路由上带的参数
 * @param {*} newSrcTabPageId 埋点字段
 * @returns 
 */
const generateBffParams = (requestInfo, newSrcTabPageId) => {
  return {
    goodsParams: getGoodsParams(requestInfo, newSrcTabPageId),
    filterParams: getFilterParams(requestInfo),
  }
}

export default generateBffParams
