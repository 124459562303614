import { transfromAndCutImg } from '../../services/resource'

function cutImg(imgUrl, designWidth, exp = {}) {
  const RESOURCE_SDK = gbCommonInfo.RESOURCE_SDK || {}
  const { deviceData = '', isSupportWeb = '', isSupprotCut = false, sceneMap = {} } = RESOURCE_SDK

  const cutData = {
    deviceData,
    isSupportWebp: Boolean(isSupportWeb),
    isSupprotCut,
    imgUrl,
    designWidth: Number(designWidth),
    sceneMap,
    exp,
  }

  return transfromAndCutImg(cutData)
}

/**
 * 资源所属域名预连接
 * 浏览器对某个域名建立连接, 发起请求后, 如果一定时间内 (大约 30s) 没有再次请求, 连接会被关闭
 * 连接关闭后, 再次请求时, 会有较长的建立连接的耗时
 * 这个函数可以在合适的时机调用, 预先建立连接
 */
export function preConnect() {
  // 需要预连接的源写在这里
  const entries = [
    location.origin, // api 接口请求
    'https://img.ltwebstatic.com', // 图片请求
  ]

  const fragment = document.createDocumentFragment()
  const links = []
  for (const entry of entries) {
    const link = document.createElement('link')
    link.rel = 'preconnect'
    link.href = entry
    links.push(link)
    fragment.appendChild(link)
  }
  document.head.appendChild(fragment)

  setTimeout(() => {
    for (const link of links) {
      document.head.removeChild(link)
    }
  }, 2000)
}

/**
 * 预加载图片
 * @param urls - string[] 图片地址列表
 * @param designWidth - number 图片的设计尺寸
 */
export function prefetchImages(urls, designWidth) {
  for (const url of urls) {
    const newUrl = cutImg(url, designWidth)
    const img = new Image()
    img.src = newUrl
  }
}

export const preloadImg = async ({ imgs = [], timeout = 3000, designWidth = 0, original }, callback) => {
  return Promise.race([
    new Promise((resolve) => {
      imgs.forEach((src) => {
        const img = new Image()
        img.src = original ? src : cutImg(src, designWidth)
        img.onload = imgLoadHandle
        img.onerror = imgLoadHandle
      })
      let loadedNum = 0
      function imgLoadHandle() {
        loadedNum++
        if(loadedNum === imgs.length) {
          callback?.()
          resolve()
        }
      }
    }),
    new Promise((resolve) => setTimeout(resolve, timeout))
  ])
}
