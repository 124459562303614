import schttp from 'public/src/services/schttp'
import { ApiCache } from '../ApiCache/index'
import { defaultRequestInfo, getCurrentToRoute, setCurrentToRoute, getPdeParams, PointerOverGoodsRequest, generateRouterParams } from './utils'
import { PRODUCT_LIST_API_URL_MAP, PRODUCT_LIST_ROUTE_NAME, NOT_CACHE_KEYS, SEARCH_PRODUCT_LIST_REQUEST_URL_MAP, ROUTE_REGEX } from './constant.js'
import { preloadImg } from '../../utlis/prefetch'
import generateParams from './generateParams'
import generateBffParams from './generateBffParams'
import { markPoint, customMarkPoint } from 'public/src/services/mark/index.js'
import { isSwitchBffApiVersion } from 'public/src/pages/common/bff-new/index'

// node列表请求 - S
let abortionInstance = null
const apiCache = new ApiCache({
  cacheTime: 1 * 60 * 1000, // 缓存时间 1 分钟
  request: async (params) => {
    if (abortionInstance) {
      abortionInstance.abort()
    }
    abortionInstance = new SchttpAbortCon()
    const route = getCurrentToRoute()
    const url = PRODUCT_LIST_API_URL_MAP[route.name]
    if (!url) {
      throw new Error('未找到对应的 url')
    }
    
    const isFirstLoad = params?.requestType === 'firstload'
    const markApiEnd = isFirstLoad && customMarkPoint({ eventName: 'apiRequestTimeGoods' })
    const res = await schttp({
      url,
      params,
      headers: getPdeParams(),
      signal: abortionInstance.signal,
      isPreLoad: true,
    })
    isFirstLoad && markApiEnd()

    function handlePreloadImgs() {
      // 对 goods 中的前 4 张商品主图 (包括 spu 图) 进行预取
      const prefetchGoods = res?.goods?.slice(0, 4) || []
      const mainImages = prefetchGoods.map(item => item.goods_img)
      const spuImages = prefetchGoods.map(item => item.spu_image?.[0]?.medium_image).filter(img => img && img !== 'filteredSpuImg')
      // 以及对图文导航的首屏图片进行预取
      const picTopNavImages = preloadPicTopNav({ nodeRes: res, toRoute: route })
      // 取搜索店铺卡片图片
      const searchStoreCardImgs = res?.searchBannerCard?.searchStoreCard?.products?.map(item => item.goods_img).filter(v => v) || []
      // 取搜索趋势卡片店铺图片
      const trendCargBg = 'https://img.ltwebstatic.com/images3_ccc/2024/05/21/45/17162772482320df6564cdf819da0dcf50402ffdba.png'
      const trendCardProductImg = res.searchBannerCard?.searchTrendCard?.mianImg // 商品图
      const trendCardLineChat = res.searchBannerCard?.searchTrendCard?.growthLabel?.growthImage // 折线图
      const trendCardImgs = [trendCardProductImg, trendCardLineChat]
      if (trendCardImgs.length) {
        trendCardImgs.push(trendCargBg)
      }
      Promise.all([
        handleGoodsImgLessen({ mainImages, prefetchGoods, isFirstLoad }),
        // 338 这个图片设计尺寸来自于列表双列布局下, 商品主图的设计稿尺寸, 参考 Item.vue 中的变量: columnDesignWidthMap
        preloadImg({ imgs: [...spuImages], designWidth: 338 }), 
        // 这里设计尺寸传 0, 是因为不用裁切, 只是做 webp 的替换
        preloadImg({ imgs: [...picTopNavImages, ...searchStoreCardImgs, ...trendCardImgs], designWidth: 0 })
      ])
    }
    handlePreloadImgs()
    return res
  },
  // 配置过滤掉不参加缓存的参数key
  notCacheKeys: NOT_CACHE_KEYS,
})

/**
 * 图文导航的首屏图片预取
 * @returns {Array}
 */
const preloadPicTopNav = ({ nodeRes, toRoute, bffFilterInfo = {}, isBff = false }) => {
  const navs = isBff ?
    bffFilterInfo.navs :
    nodeRes?.picTopNav?.[0]?.navs
  const counter = (count) => (navs || []).slice(0, count).map(item => item.nav_img).filter(img => img)
  const listAbtResult = nodeRes?.listAbtResult || {}
  const isSearchPage = toRoute?.path?.match(ROUTE_REGEX.search)

  if (isSearchPage) {
    const SearchNavigationTwoLine = listAbtResult.SearchNavigationTwoLine?.p?.SearchNavigationTwoLine
    return SearchNavigationTwoLine === 'FirstScreen' ? counter(10) : counter(5)
  }
  const ListNavigationTwoLine = listAbtResult.ListNavigationTwoLine?.p?.ListNavigationTwoLine
  return ListNavigationTwoLine === 'FirstScreen' ? counter(10) : counter(5)
}

async function handleGoodsImgLessen({ mainImages, prefetchGoods, isFirstLoad }) {
  try {
    if (!prefetchGoods?.length) return
    // 将图片进行裁切100x  快速上屏加载。
    const lessenImgs = mainImages.map((item, index) => {
      const [imgUrl] = `${item}`.split('_thumbnail_')
      const _imgThumb = `${imgUrl}_thumbnail_100x.webp`
      if (prefetchGoods[index]) {
        prefetchGoods[index]._imgThumb = _imgThumb // 保存缩略图地址
      }
      return _imgThumb
    })
    const markImgEnd = isFirstLoad && customMarkPoint({ eventName: 'preloadListImg' })
    await preloadImg({ imgs: lessenImgs, original: true, timeout: 1000 })
    markImgEnd?.()
    preloadImg({ imgs: mainImages, designWidth: 338 })
  } catch(e) {
    // console.error(e)
  }
}

function clearApiCache() {
  apiCache.clear()
}
// node列表请求 - E

// bff列表请求 - S
let goodsAbortionInstance = null
const goodsApiCache = new ApiCache({
  cacheTime: 1 * 60 * 1000, // 缓存时间 1 分钟
  request: async (params) => {
    if (goodsAbortionInstance) {
      goodsAbortionInstance.abort()
    }
    goodsAbortionInstance = new SchttpAbortCon()
    const markApiEnd = customMarkPoint({ eventName: 'apiBffGoods' })
    const res = await schttp({
      method: 'POST',
      url: SEARCH_PRODUCT_LIST_REQUEST_URL_MAP.goods,
      params,
      signal: goodsAbortionInstance.signal,
      isPreLoad: true,
      useBffApi: true
    })
    markApiEnd()
  
    function handlePreloadImgs() {
      // 对 goods 中的前 4 张商品主图 (包括 spu 图) 进行预取
      const prefetchGoods = res?.info?.products?.slice(0, 4) || []
      const mainImages = prefetchGoods.map(item => item.goods_img)
      const spuImages = prefetchGoods.map(item => item.spu_images?.spu_images?.[0])
      // 取搜索店铺卡片图片
      // const searchStoreCardImgs = res?.searchBannerCard?.searchStoreCard?.products?.map(item => item.goods_img).filter(v => v) || []
      // 取搜索趋势卡片店铺图片
      // const trendCargBg = 'https://img.ltwebstatic.com/images3_ccc/2024/05/21/45/17162772482320df6564cdf819da0dcf50402ffdba.png'
      // const trendCardProductImg = res.searchBannerCard?.searchTrendCard?.mianImg // 商品图
      // const trendCardLineChat = res.searchBannerCard?.searchTrendCard?.growthLabel?.growthImage // 折线图
      // const trendCardImgs = [trendCardProductImg, trendCardLineChat]
      // if (trendCardImgs.length) {
      //   trendCardImgs.push(trendCargBg)
      // }

      Promise.all([
        handleGoodsImgLessen({ mainImages, prefetchGoods }),
        // 338 这个图片设计尺寸来自于列表双列布局下, 商品主图的设计稿尺寸, 参考 Item.vue 中的变量: columnDesignWidthMap
        preloadImg({ imgs: [...spuImages], designWidth: 338 }), 
        // 这里设计尺寸传 0, 是因为不用裁切, 只是做 webp 的替换
        // preloadImg({ imgs: [...searchStoreCardImgs, ...trendCardImgs], designWidth: 0 })
      ]).finally(() => {})
    }
    handlePreloadImgs()
    return res
  },
  // 配置过滤掉不参加缓存的参数key TODO:
  notCacheKeys: NOT_CACHE_KEYS,
})

let filterAbortionInstance = null
const filterApiCache = new ApiCache({
  cacheTime: 1 * 60 * 1000, // 缓存时间 1 分钟
  request: async (params) => {
    if (filterAbortionInstance) {
      filterAbortionInstance.abort()
    }
    filterAbortionInstance = new SchttpAbortCon()
    const markApiEnd = customMarkPoint({ eventName: 'apiListFilter' })
    const res = await schttp({
      url: SEARCH_PRODUCT_LIST_REQUEST_URL_MAP.filters,
      params,
      signal: filterAbortionInstance.signal,
      isPreLoad: true,
      useBffApi: true
    })
    markApiEnd()
    return res
  },
  // 配置过滤掉不参加缓存的参数key TODO:
  notCacheKeys: NOT_CACHE_KEYS,
})

let cccFeedbackAbortionInstance = null
const cccFeedbackApiCache = new ApiCache({
  cacheTime: 10 * 60 * 1000, // 缓存时间 5 分钟
  request: async () => {
    if (cccFeedbackAbortionInstance) {
      cccFeedbackAbortionInstance.abort()
    }
    cccFeedbackAbortionInstance = new SchttpAbortCon()
  
    const res = await schttp({
      url: SEARCH_PRODUCT_LIST_REQUEST_URL_MAP.cccFeedback,
      params: {},
      signal: cccFeedbackAbortionInstance.signal,
      isPreLoad: true,
      useBffApi: true
    })
  
    return res
  },
  // 配置过滤掉不参加缓存的参数key TODO:
  notCacheKeys: NOT_CACHE_KEYS,
})
// bff列表请求 - E

/**
 * 获取商品列表数据的接口调用
 * */ 
const requestProductListApi = async (requestInfo, newSrcTabPageId, toRoute, useNodeApi) => {
  if (typeof window === 'undefined') return

  const nodeRequest = getNodeRequest(requestInfo, newSrcTabPageId)
  const bffRequestQueue = getBffRequestQueue(requestInfo, toRoute, newSrcTabPageId)
  
  try {
    if (bffRequestQueue.length && !useNodeApi) {
      const result = await Promise.all([
        nodeRequest({ useBffProducts: 1, useBffFilters: 1 }), 
        ...bffRequestQueue
      ])

      const nodeResult = result[0] || {}
      const bffGoodsResult = result[1] || {}
      const bffCccFeedbackResult = result[2] || {}
      const bffFilterResult = result[3] || {}

      const picTopNavImages = preloadPicTopNav({ nodeRes: nodeResult, toRoute, bffFilterInfo: bffFilterResult.info, isBff: true })
      preloadImg({ imgs: picTopNavImages, designWidth: 0 })

      return {
        ...nodeResult,
        bffProductsInfo: bffGoodsResult.code === '0' ?  { code: '0', ...bffGoodsResult.info } : {},
        bffFilterInfo: bffFilterResult.code === '0' ? bffFilterResult.info : {},
        bffCccFeedbackInfo: bffCccFeedbackResult.code === '0' ? bffCccFeedbackResult.info : {}
      }
    } else {
      const result = await nodeRequest()
      return result
    }
  } catch (e) {
    console.error(e)
    return {}
  }
}

const getNodeRequest = (requestInfo, newSrcTabPageId) => {
  const params = generateParams(requestInfo, newSrcTabPageId)

  return ({ useBffProducts, useBffFilters } = {}) => apiCache.request({ ...params, useBffProducts, useBffFilters })
}

/**
 * bff 请求队列
*/
const getBffRequestQueue = (requestInfo, toRoute, newSrcTabPageId) => {
  const isSearchSwitchBff = isSwitchBffApiVersion(['search_v1'])?.search_v1
  const isSearch = toRoute?.path?.match(ROUTE_REGEX.search)

  if (isSearch && isSearchSwitchBff) {
    const { goodsParams, filterParams } = generateBffParams(requestInfo, newSrcTabPageId)

    const requestQueue = [
      goodsApiCache.request(goodsParams), // 商品数据
      cccFeedbackApiCache.request({ v: '1.0' }), // 反馈弹窗
    ]

    if (requestInfo.type !== 'nextpage') {
      requestQueue.push(filterApiCache.request(filterParams)) // 图文导航，分类/属性/tsp筛选，标签云
      
    }
    
    return requestQueue
  }

  return []
}

/**
 * 检测是否数据已经缓存
*/
const getProductListApiCache = (requestInfo, newSrcTabPageId, toRoute) => {
  const isSearchSwitchBff = isSwitchBffApiVersion(['search_v1'])?.search_v1
  const isSearch = toRoute?.path?.match(ROUTE_REGEX.search)
  if (isSearch && isSearchSwitchBff) {
    const { goodsParams } = generateBffParams(requestInfo, newSrcTabPageId)
    return apiCache.getCacheData(goodsParams)
  } else {
    const params = generateParams(requestInfo, newSrcTabPageId)
    return apiCache.getCacheData(params)
  }
}

let prehtmlFlag = false // 单页水合时，不发起预请求
function changePreHtmlUpdate(value) {
  prehtmlFlag = value
}

/**
 * 路由的预请求
 * */
function handleProductListPreRequestBeforeEachRoute(to, from) {
  if (prehtmlFlag) return
  if (to?.name === 'product-list-v2') {
    markPoint({ eventName: 'RouterStartTrigger', measureTo: 'RouterEndTrigger' })
  }
  const isProductListRoute = PRODUCT_LIST_ROUTE_NAME.includes(to.name)
  if (!isProductListRoute) return
  
  setCurrentToRoute(to)
  const isSpaRouteChange = (fromRoute) => Boolean(fromRoute.name)
  const isSameRouteChange = (to, from) => to.name === from.name
  if (isSpaRouteChange(from)) {
    if (!isSameRouteChange(to, from)) {
      requestProductListApi(defaultRequestInfo(to.query), undefined, to)
    }
  } else {
    // 说明是 ssr 渲染了列表页, 不需要接口预请求
  }
}

function handleProductListPreRequestAfterEachRoute(to, from) {
  if (!prehtmlFlag && to?.name === 'product-list-v2') {
    markPoint({ eventName: 'RouterEndTrigger', measureTo: 'PageWaitInit' })
  }
}

if (typeof window !== 'undefined') {
  window.apiCacheProducts = apiCache
}

const abortRequest = (abortReason) => {
  abortionInstance?.abort?.(abortReason)
  goodsAbortionInstance?.abort?.(abortReason)
  filterAbortionInstance?.abort?.(abortReason)
}

/**
 * 触摸提前预请求方法。让请求提前触发
 * */
const pointerOverGoodsRequest = new PointerOverGoodsRequest({
  fetch: (path) => {
    const toRouteParams = generateRouterParams(path)
    if (!toRouteParams) return
    handleProductListPreRequestBeforeEachRoute(toRouteParams, { name: 'preload-request' })
  },
  cancelToken: () => abortRequest(),
})

export {
  abortionInstance,
  abortRequest,
  requestProductListApi,
  setCurrentToRoute,
  handleProductListPreRequestBeforeEachRoute,
  handleProductListPreRequestAfterEachRoute,
  pointerOverGoodsRequest,
  changePreHtmlUpdate,
  getProductListApiCache,
  clearApiCache,
}
