
import schttp from 'public/src/services/schttp'

/*
** url: 页面地址
** options: 配置项 { scene: 'special', isReturnUrl: false }
** scene: sw代理、页面预取场景 1、cccx 2、special
** isReturnUrl: 是否返回预取url，跳转时使用，不然无法匹配到sw缓存
**/

export const preFetchOrGetPageUrl = ({ url, options = {} } = {}) => {
  if (!url) return
  try {
    const { scene = 'special', isReturnUrl = false } = options || {}
    const { host, SW_FETCH_HTML_CONFIG = {} } = window.gbCommonInfo || {}
    // 是否有顶部banner
    const hasTopBanner = window.gbCommonInfo?.hasTopBanner || (window.sheinBanner?.topBanner?.Local?.setFlag && window.sheinBanner?.topBanner?.Local?.setFlag !== 'hide')
    if (hasTopBanner) {
      url = `${url}${url.indexOf('?') > -1 ? `&hasTopBanner=1` : `?hasTopBanner=1` }`
    }
    const { enableSceneMap = [], preFetchSceneMap = [] } = SW_FETCH_HTML_CONFIG || {}
    // 是否开启sw页面代理
    if (!enableSceneMap.includes(scene)) return url
    // sw页面代理url
    const requestUrl = `${url}${url.indexOf('?') > -1 ? '&proxy_enable=1' : '?proxy_enable=1' }`
    if(isReturnUrl) return requestUrl
    // 页面是否预取
    if(!preFetchSceneMap.includes(scene)) return

    if (window.SW_Loaded) {
      schttp({
        url: `${host}${requestUrl}`,
        schttp: { apiLogSkip: true }
      })
      return
    }
    window.addEventListener('SW_Loaded', () => {
      schttp({
        url: `${host}${requestUrl}`,
        schttp: { apiLogSkip: true }
      })
    })
  } catch (error) {
    return url
  }
}
  
