import { parseQueryString } from '@shein/common-function'
import { LIST_PAGE_PATHS } from 'public/src/pages/product_app/router/constants.js'

let currentToRoute = null

function getCurrentToRoute() {
  return currentToRoute
}

function setCurrentToRoute(route) {
  currentToRoute = route
}

function getPdeParams() {
  const headers = {}
  const date = new Date()
  headers['screen-pixel'] = window.screen.width + 'X' + window.screen.height
  headers['local-time'] = `${date.getFullYear()}/${
    date.getMonth() + 1
  }/${date.getDate()} ${date.getHours()}:${date.getMinutes()}:${date.getSeconds()}`
  return headers
}

class PointerOverGoodsRequest {
  constructor({ cancelToken, fetch, TIME }) {
    if (!fetch) throw new Error('update is required')
    this.cancelToken = cancelToken // 取消请求的实例
    this.fetch = fetch
    this.TIME = TIME || 500 // 如果没有click事件的话，默认500ms后取消请求 
  }

  triggerNotice(url, options = {}) {
    if (url === this.requestUrl) return // 请求的url和上一次的url一样，不做处理(防止取消上次请求，发起新请求)
    this._cancel()
    this.fetch(url)
    this.requestUrl = url
    const { isNeedTimer = true } = options 

    if (isNeedTimer) {
      this.timer = setTimeout(() => {
        this._cancel()
      }, this.TIME)
    }
  }

  // 清除定时器，说明当前请求是有效的。（必须调用）
  click() {
    this._clear()
  }

  _clear() {
    this.requestUrl = null
    clearTimeout(this.timer)
  }

  _cancel() {
    this.cancelToken && this.cancelToken()
    this._clear()
  }
}

function defaultRequestInfo(query = {}) {
  return {
    type: 'firstload',
    query: {
      page: 1,
      limit: 10,
      ...query
    }
  }
}

// 处理链接成请求参数
function generateRouterParams(path) {
  const isProductsPage = LIST_PAGE_PATHS.some((reg) => new RegExp(reg).test(path))
  if (!isProductsPage) return

  const [pathname, searchSrc] = path.split('?')
  const toParams = {
    name: 'product-list-v2',
    path: pathname,
    query: parseQueryString(searchSrc),
  }
  return toParams
}

export {
  getCurrentToRoute,
  setCurrentToRoute,
  getPdeParams,
  PointerOverGoodsRequest,
  defaultRequestInfo,
  generateRouterParams,
}
