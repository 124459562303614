const PRODUCT_LIST_API_URL_MAP = {
  'product-list-v2': '/api/productList/info/get',
  'shein-picks': '/api/productList/picksInfo/get',
  'picked-info-list': '/api/recProductList/recInfo/get',
  'feedback-rec-list': '/api/recProductList/recInfo/get',
  'discount-channel-list': '/api/discountChannelList/disInfo/get',
}

// 属于泛列表的路由名字: 一般列表, 信息流列表 (包括大卡片), 实时反馈列表
const PRODUCT_LIST_ROUTE_NAME = [
  'product-list-v2',
  'picked-info-list',
  'feedback-rec-list',
  'discount-channel-list',
  'shein-picks',
]

const NOT_CACHE_KEYS = ['scici', 'src_module', 'src_identifier', 'src_tab_page_id', 'new_src_tab_page_id', 'src_identifier_pre_search']

const SEARCH_PRODUCT_LIST_REQUEST_URL_MAP = {
  goods: '/product/get_products_by_keywords', // 商品数据
  filters: '/search/get_aggregation_filters', // 分类，属性，tsp筛选，图文导航，标签云
  cccFeedback: '/ccc/communal/switch'
}

const ROUTE_REGEX = {
  search: /pdsearch\/([^\/]+)\/?$/,
  real: /-c-(\d+)\.html$/,
  select: /((\w+)\/)?[^/]+-sc-([A-Za-z0-9]+)\.html$/,
}

export {
  PRODUCT_LIST_ROUTE_NAME,
  PRODUCT_LIST_API_URL_MAP,
  NOT_CACHE_KEYS,
  SEARCH_PRODUCT_LIST_REQUEST_URL_MAP,
  ROUTE_REGEX
}
